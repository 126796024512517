.headerLogo {
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_container {
  background-color: var(--primary-color);
}

.navbar-brand {
  background-color: var(--secondary-bg);
  height: 100%;
  margin-right: 0;
  padding: 1.2rem 0 0.2rem 0;
}

.headerLogo_contaienr {
  width: 210px;
}

.headerLogo_contaienr img {
  width: 100%;
}

.header {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  z-index: 20;
  position: relative;
}

.navbar {
  background-color: var(--primary-color) !important;
  padding: 0;
}

.navbar-collapse {
  display: flex;
  flex-basis: auto;
  background-color: var(--primary-color);
  width: 100%;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  height: 100%;
}

.dropdownSelect .p-dropdown {
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.3rem;
  gap: 0.5rem;
  min-width: 200px;
}

.dropdownSelect .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  color: var(--primary-color) !important;
}

.p-dropdown-panel {
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.p-dropdown-panel ul {
  padding: 0 !important;
}

.p-dropdown-panel ul li {
  padding: 0.2rem 0;
  text-align: center;
  color: var(--secondary-text);
}

.profileLink img {
  width: 2.3rem;
}

.profileDropdown .dropdown-menu {
  border: 1px solid var(--primary-color);
}

.profileLink,
.profileBtnToggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.profileBtnToggle {
  padding: 0;
}

.profileBtnToggle p,
.profileBtnToggle h6 {
  margin: 0;
  text-align: right;
}

.profileBtnToggle h6 {
  color: var(--secondary-text);
  font-size: 12px;
  font-weight: 600;
}

.profileBtnToggle p {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
}

.headerIcons {
  width: 2.8rem;
  height: 2.8rem;
}

.headerIcons img {
  width: 100%;
  height: 100%;
}

.navbar-nav {
  gap: 0.6rem;
  align-items: center;
}

.bTnAndProfile {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.bTnAndProfileLeft {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 991px) {
  .bTnAndProfile {
    width: 100%;
    background-color: var(--primary-bg);
    height: 100%;
    padding: 0.8rem 1.5rem 1rem 1rem;
  }

  .lg_header {
    display: flex;
    width: 100%;
  }

  .settingHeader {
    width: 2rem !important;
  }

  .headerIcons {
    width: 2.5rem;
    height: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .headerIcons p {
    margin: 0;
    font-weight: 500;
  }

  .navbar-nav {
    flex-direction: row;
  }
}

.profileDropdown .profileBtnToggle img {
  height: 1.5rem;
  width: 1.5rem;
}

.profileDropdown .dropdown-toggle::after {
  content: none;
}

.header {
  position: sticky;
  top: 0;
  z-index: 99;
}

.accesibilities {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.accesibilitiesIcons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.accesibilities button {
  border: none;
  background-color: transparent;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  border-radius: 5px;
}

.accesibilities .rsrvHeadBtn p {
  margin: 0;
  font-size: 12px;
  color: var(--light-black);
  font-weight: 500;
}

.accesibilities .rsrvHeadBtn img {
  width: 0.6rem;
}

.accesibilities .rsrvHeadBtn {
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid var(--white-text);
  height: 30px;
  transition: all 0.1s ease-in-out;
}

.selectedHeadBtn {
  background-color: var(--primary-bg) !important;
}

.timeANdDateHead {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.timeANdDateHead p {
  margin: 0;
  font-size: 12px;
  padding: 0 0.5rem;
  color: var(--white-text);
  font-weight: 500;
  white-space: nowrap;
}

.dateHeadb {
  border-right: 1px solid var(--white-text);
}

.saparatorDiv {
  border-right: 1px solid var(--white-text);
  width: 1px;
  height: 56px;
}

.headSearch {
  position: relative;
}

.searchDropdownBox {
  position: absolute;
  background-color: var(--primary-bg);
  border-radius: 5px;
  padding: 1rem 0;
  z-index: 999999;
  margin-top: 0.3rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.searchDropdownBox ul {
  list-style: none;
  padding: 0;
}

.searchDropdownBox ul li {
  cursor: pointer;
  padding: 0.2rem 0.5rem 0.2rem 1.5rem;
}

.searchDropdownBox ul li:hover {
  background-color: var(--secondary-bg);
}

.headSearch input {
  height: 32px;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0 0.5rem;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .headSearch input {
    font-size: 14px !important;
  }

  .searchDropdownBox ul li {
    font-size: 14px !important;
  }
}

.headSearchInput {
  display: flex;
  align-items: center;
}

.headSearchInput {
  background-color: var(--primary-bg);
  padding: 0 0.5rem;
  border-radius: 5px;
  border-bottom: 1px solid var(--light-black);
}

@media screen and (max-width: 1020px) {
  .navbar-collapse {
    padding: 0.5rem 1rem;
  }

  .menuOpenClose {
    padding-left: 0 !important;
  }

  .accesibilities {
    gap: 0.5rem;
  }

  .navbar-nav {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-nav {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 1150px) {
  .hideAfter1150 {
    display: none;
  }

  .showOnActive {
    display: flex !important;
  }

  .navbar-nav {
    gap: 0.5rem;
  }

  .profileLink img {
    width: 2rem;
  }

  .profileDropdown .profileBtnToggle .togglePBTN {
    width: 1rem;
    height: 1rem;
  }

  .profileLink,
  .profileBtnToggle {
    gap: 0.3rem !important;
  }

  .paddRight0 {
    padding-right: 0 !important;
  }

  .filterBodyDashB {
    padding: 0 0.7rem !important;
  }
}

@media screen and (max-width: 991px) {
  .hideAfter991 {
    display: none !important;
  }

  .filterBodyDashB {
    padding: 0 0.5rem !important;
  }
}

/* ---------------------------Sub Header Css------------------------- */

.sub_header {
  background-color: var(--primary-color);
  width: 100%;
  height: 100%;
}

/*-------------Model Css------------*/
/* Custom styles for the modal */
.modal-content {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.modal_body {
  display: flex;
  font-size: 1.1rem;

  padding: 30px;
  justify-content: center;
}
.logoutYesBtn,
.logoutNoBtn {
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  background-color: var(--primary-color);
  border: none;
}
.logoutYesBtn:hover {
  background-color: var(--green-bg);
}
.logoutNoBtn:hover {
  background-color: var(--red-bg);
  border: none;
}
/*----Notification Side Bar----*/
.notificationBar {
  height: 100vh;
  width: 400px;
  background-color: var(--white-text);
  position: absolute;
  right: 0;
  top: 0;
}

.noti_Text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--grey-bg);
}

.closeNoti button {
  border: none;
  background-color: transparent;
}

.closeNoti button img {
  width: 0.8rem;
}

.noti_Text label,
.All_request_Cont {
  font-size: 14px;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
}

.Request_section button,
.AlL_section button,
.Request_section,
.AlL_section {
  width: 100%;
}

.Request_section,
.AlL_section {
  border-bottom: 2px solid transparent;
}

.All_request_Cont {
  display: flex;
  margin-top: 0.8rem;
  background-color: var(--secondary-bg);
  justify-content: space-around;
  margin-bottom: 6px;
}

.AlL_section button,
.Request_section button {
  font-weight: 500;
  border: none;
  padding: 8px;
  background-color: var(--secondary-bg);
}

.onSelectedNoti {
  border-bottom: 2px solid var(--primary-color);
}

.Notification_hall,
.Notification_hall2,
.Notification_hall3 {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-bg);
  padding-bottom: 10px;
  padding-top: 8px;
  margin-bottom: 8px;
  position: relative;
}
.notiConTenr {
  background-color: var(--secondary-bg);
  bottom: 0;
  width: 100%;
}
.noticontent {
  max-height: 500px;
  overflow-y: auto;
}

.grppp {
  gap: 9px;
}

.grppp img {
  height: 21px;
  width: 21px;
  margin-top: 3px;
}
.imgGrP img,
.imgGrP button {
  height: 10px;
  width: 10x;
  margin-top: 3px;
  position: absolute;
  right: 3px;
  border: none;
}

.grppp label,
.Name_aRriVe span {
  font-size: 12px;
  padding: 6px;
  font-weight: 500;
}
.reservastion_rq > label {
  padding-left: 11px;
}

.Name_aRriVe span {
  color: var(--primary-color);
  max-width: fit-content;
}

.dates_aRriVe,
.Name_aRriVe {
  margin-left: 1.7rem;
}

.accpt_decln_btn {
  display: flex;
  gap: 10px;
  margin-left: 2rem;
}

.acceptBtnn,
.DeclineBtnn,
.clearNotiBtn {
  margin-top: 6px;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 6px;
  border: none;
  outline: 1.6px solid var(--lightRed-bg);
  background-color: var(--primary-bg);
}

.clearNotiBtn {
  outline: none !important;
  background-color: var(--secondary-bg);
}
.clearNotiBtn:hover {
  color: var(--primary-bg);
  background-color: var(--primary-color);
}
.acceptBtnn:focus,
.DeclineBtnn:focus {
  color: var(--primary-bg);
  background-color: var(--primary-color);
  outline: none;
}
.Notification_hall4 {
  margin-top: 10px;
  display: flex;
}

.noticountsets {
  position: relative;
}

.noticountsets span {
  position: absolute;
  top: -4px;
  right: -6px;
  font-size: 11px;
  background-color: var(--primary-bg);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.dropdown-item {
  border-radius: 8px;
  cursor: pointer !important;
}
.profilimg {
  height: 23px;
  width: auto;
}
.logOutimg {
  height: 19px;
  width: auto;
  font-size: 15px;
  font-weight: 500;
}
.profilimg img,
.logOutimg img {
  object-fit: fill;
}
.no_noti {
  padding: 1rem;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.no_noti span {
  color: var(--primary-color);
}

.accesibilities .rsrvHeadBtn:hover {
  background-color: var(--primary-bg);
  color: var(--white-text);
}