.infoDetails p {
  margin-bottom: 0.4rem;
  font-size: 14px;
}

.ReScheduleModal label {
  font-size: 13px;
  padding-left: 4px !important;
}
.ReScheduleModal input::placeholder {
  font-size: 13px;
}
.calnderIcon {
  cursor: pointer;
  right: 0.8rem;
  top: 13px;
  position: absolute;
  height: 13px;
  width: 13px;
}

.clnderInput {
  position: relative;
}

#arrived_date {
  border: 1px solid #b1b1b1 !important;
}
